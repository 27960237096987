import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { IntentReasonMap } from 'src/typings/call.types';
import { twMerge } from 'tailwind-merge';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: number[];
  intentType: 'intent' | 'manual_intent';
};

export default function UserIntentFilter({ onFilterChange, intentType, prevFilter }: Props) {
  const iconRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState<number[]>(prevFilter || []);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);

  const handleChange = (filterKey: number) => {
    const index = selectedFilters?.findIndex((l) => l === filterKey);
    let updatedFilters = [];
    if (index === -1) {
      updatedFilters = [...selectedFilters, filterKey];
      setSelectedFilters([...updatedFilters]);
    } else {
      updatedFilters = selectedFilters.filter((i) => i !== filterKey);
      setSelectedFilters([...updatedFilters]);
    }
    onFilterChange({ [intentType]: [...updatedFilters] });
  };

  const onClearAll = () => {
    setSelectedFilters([]);
    onFilterChange({ [intentType]: [] });
  };

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilters.length > 0 && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {selectedFilters.length}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[350px]">
          <div className="w-full flex justify-between items-center">
            <div className="text-lg font-medium">{`Filters (${selectedFilters.length})`}</div>
            <div
              onClick={onClearAll}
              className={twMerge(
                'text-sm cursor-pointer text-primaryButton',
                selectedFilters.length === 0 ? 'text-gray-400 cursor-not-allowed' : '',
              )}
            >
              Clear all
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col gap-y-1 h-80 overflow-auto">
              {Object.entries(IntentReasonMap).map((item) => {
                const isSelected = selectedFilters.findIndex((i) => i === item[0]) !== -1;
                return (
                  <div
                    className="flex justify-start items-center gap-x-1 cursor-pointer hover:rounded-lg hover:bg-gray-100 transition-colors ease-in-out"
                    onClick={() => handleChange(item[0])}
                  >
                    <Checkbox checked={isSelected} />
                    <div className="text-[12px] font-normal">{item[1]}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
