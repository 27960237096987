import moment from 'moment';
import { useGetCallStats, useGetPickStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload, TimeOfDay } from 'src/typings/stats.types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Bar,
  BarChart,
} from 'recharts';
import { useMemo } from 'react';
import { prepareTelephonyData, prepareTelephonyTotalCallsData, prepareTimeOfDayData } from '@utils/stats.utils';
import BarChartLoader from '@components/Loader/BarChartLoader';
import { Telephony } from 'src/typings/call.types';

type Props = {
  startDate: StatsPayload['start_date'];
  companyId: StatsPayload['company_id'];
  endDate?: StatsPayload['end_date'];
};

export default function PickStats(props: Props) {
  const payload = {
    company_id: props.companyId,
    metric_type: CallStatsMetricType.PICK_STATS,
    start_date: moment(props.startDate).format('YYYY-MM-DD'),
    end_date: props?.endDate ? moment(props?.endDate).format('YYYY-MM-DD') : undefined,
    // telephony: props?.telephony,
    // time_of_day: props?.timeOfDay,
  };
  const { data, isLoading } = useGetPickStats({ ...payload });
  const { data: plivoData } = useGetPickStats({ ...payload, telephony: Telephony.Plivo });
  const { data: exotelData } = useGetPickStats({ ...payload, telephony: Telephony.Exotel });

  const { data: plivoCallData } = useGetCallStats({
    ...payload,
    metric_type: CallStatsMetricType.CALL_STATS,
    telephony: Telephony.Plivo,
  });
  const { data: exotelCallData } = useGetCallStats({
    ...payload,
    metric_type: CallStatsMetricType.CALL_STATS,
    telephony: Telephony.Exotel,
  });

  const combinedTelephonyData = useMemo(() => {
    if (plivoData && exotelData) {
      return prepareTelephonyData(plivoData, exotelData);
    } else {
      return null;
    }
  }, [plivoData, exotelData]);

  const combinedTelephonyTotalCallsData = useMemo(() => {
    if (plivoCallData && exotelCallData) {
      return prepareTelephonyTotalCallsData(plivoCallData, exotelCallData);
    } else {
      return null;
    }
  }, [plivoCallData, exotelCallData]);

  return (
    <div className="flex flex-col justify-start items-start w-full gap-4">
      <div className="flex justify-between items-start gap-4 w-full">
        {combinedTelephonyData && plivoData && exotelData && (
          <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
            <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
              <div className="text-sm  font-medium mb-4">Plivo vs Exotel Pick rate</div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={500} data={combinedTelephonyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="pickRate1" stroke="blue" activeDot={{ r: 8 }} name="Plivo" />
                  <Line type="monotone" dataKey="pickRate2" stroke="red" activeDot={{ r: 8 }} name="Exotel" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
        {combinedTelephonyData && plivoData && exotelData && (
          <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
            <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
              <div className="text-sm  font-medium mb-4">Plivo vs Exotel PTP rate</div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={500} data={combinedTelephonyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="ptp1" stroke="blue" activeDot={{ r: 8 }} name="Plivo" />
                  <Line type="monotone" dataKey="ptp2" stroke="red" activeDot={{ r: 8 }} name="Exotel" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between items-start gap-4 w-full">
        {combinedTelephonyTotalCallsData && plivoCallData && exotelCallData && (
          <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
            <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
              <div className="text-sm  font-medium mb-4">Total Calls: Plivo vs Exotel</div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={500} data={combinedTelephonyTotalCallsData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="totalCalls1" stroke="red" activeDot={{ r: 8 }} name="Plivo" />
                  <Line type="monotone" dataKey="totalCalls2" stroke="green" activeDot={{ r: 8 }} name="Exotel" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between items-start gap-4 w-full">
        <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
          <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
            <div className="text-sm  font-medium mb-4">Total Borrowers called</div>
            {isLoading ? (
              <BarChartLoader />
            ) : (
              data && (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart width={100} height={100} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total_borrowers" stackId="a" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
