import { FilterIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: number[];
};

const spamOptions = [
  { name: 'Yes', id: true },
  { name: 'No', id: false },
];

export default function SpamFilter({ onFilterChange }: Props) {
  const iconRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState<boolean | null>(null);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);

  const onSpamFilter = (e: React.MouseEvent<HTMLButtonElement>, val: boolean) => {
    if (val === selectedFilter) {
      setSelectedFilter(null);
      onFilterChange(null);
    } else {
      setSelectedFilter(val);
      onFilterChange(val);
    }
  };

  return (
    <div>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilter !== null && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {1}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col items-start gap-y-2 py-2 px-4 w-full">
          {spamOptions.map((item) => {
            return (
              <div key={item.name} className="flex justify-start items-center gap-x-2">
                <Checkbox checked={item.id === selectedFilter} onClick={(e) => onSpamFilter(e, item.id)} />
                <div>{item.name}</div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
}
