import { CallDetailsLoader } from '@components/Loader';
import { TopNavbar } from '@components/TopNavbar';
import { useParams } from 'react-router-dom';
import { useGetCallLogDetails } from 'src/hooks/apis/calls/useGetCallLogDetails';
import CallLogDetails from 'src/container/CallLogDetails/CallLogDetails';

type Props = {};

export default function CallDetailsPage({}: Props) {
  const { id = '' } = useParams();
  const { data, isLoading } = useGetCallLogDetails({ callId: id, enabled: true });

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen">
      <TopNavbar showBackButton />
      <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]">
        {isLoading ? <CallDetailsLoader /> : data ? <CallLogDetails callDetails={data} /> : null}
      </div>
    </div>
  );
}
