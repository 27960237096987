import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//components
import { TextField } from '@components/TextField';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { DashboardLoader } from '@components/Loader';
import { CheckIcon, PencilAltIcon, XIcon } from '@heroicons/react/outline';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
//hooks
import { useDeleteScheduledCall, useGetCallLogs } from 'src/hooks/apis/calls';
//types
import { BorrowerCallNode, BorrowerNode, CallLog, CallType } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';
//api
import { CoreAPIs } from 'src/api';
//utils
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
import { getXMinutesFromNow } from '@utils/call.utils';
import moment from 'moment';
import { queryClient } from 'src/queryClient';
import { callLogs } from 'src/queryClient/queryKeys';
import { twMerge } from 'tailwind-merge';

type Props = {
  company: CompanyNode | null;
  onScrollToTop: () => void;
};

type BorrowerDetailNode = {
  Borrower: BorrowerNode;
  BorrowerCall: BorrowerCallNode;
};
const enableRowSelection = true;

const size = 50;

export default function ScheduledCalls({ company, onScrollToTop }: Props) {
  const prevPage = getDataFromStorage('page');
  const navigateTo = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [page, setPage] = useState<number>(prevPage || 1);
  const [pageValue, setPageValue] = useState<number>(prevPage || 1);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [editScheduledDate, toggleScheduledDate] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<CallLog[]>([]);
  const [dueDate, setDueDate] = useState<Date>(getXMinutesFromNow(5));
  const [borrowerId, setBorrowerId] = useState<BorrowerNode['id'] | null>(null);
  const [borrowerDetails, setBorrowerDetails] = useState<BorrowerDetailNode[]>([]);
  const { mutateAsync: deleteScheduledCall } = useDeleteScheduledCall();
  const { data, isLoading } = useGetCallLogs({
    page,
    size,
    callType: CallType.SCHEDULED,
    companyId: company?.id === 'all' ? '' : company?.id,
  });

  const onRowPress = (id: number | string) => {
    navigateTo(`/scheduled/${id}`);
  };

  const updateScheduledDate = async (
    e: React.MouseEvent<HTMLButtonElement>,
    borrowerCallsItd: CallLog['borrowercalls_id'],
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await CoreAPIs.updateCallInfo(borrowerCallsItd, {
        borrower_id: borrowerId,
        call_scheduled_time: moment(dueDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
      });
      queryClient.invalidateQueries([callLogs, CallType.SCHEDULED, page, company?.id]);
      toggleScheduledDate(false);
      setActiveIndex(-1);
      setDueDate(getXMinutesFromNow(5));
      setBorrowerId(null);
      toast.success('Next call schedule updated');
    } catch (error) {
      toast.error('Please select time at least 5mins from now');
      console.error('Error::', error);
    }
  };

  const getBorrowerDetails = async (borrowerCallsId: CallLog['borrowercalls_id']) => {
    const res = await CoreAPIs.getScheduledCallInfo(borrowerCallsId);
    setBorrowerDetails([...borrowerDetails, res]);
    return res;
  };

  const onPressEdit = async (
    e: React.MouseEvent<HTMLButtonElement>,
    borrowerCallsId: CallLog['borrowercalls_id'],
    index: number,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await getBorrowerDetails(borrowerCallsId);
    const borrowerId = res?.Borrower.id;
    setBorrowerId(borrowerId);
    toggleScheduledDate(true);
    setActiveIndex(index);
  };

  const scheduledCalls = data?.items.sort((a, b) => moment(a.call_scheduled_time).diff(moment(b.call_scheduled_time)));

  const addItem = (log: CallLog) => {
    setSelectedItems([...selectedItems, log]);
  };

  const removeItem = (log: CallLog) => {
    setSelectedItems(selectedItems.filter((i) => i.borrowercalls_id !== log.borrowercalls_id));
  };

  const onDelete = async () => {
    const promiseArr: Promise<unknown>[] = [];
    try {
      selectedItems.forEach((i) => {
        promiseArr.push(
          deleteScheduledCall({
            callId: i.borrowercalls_id,
            page,
            size,
            callType: CallType.SCHEDULED,
            companyId: company?.id,
          }),
        );
      });
      await Promise.all(promiseArr);
      setSelectedItems([]);
      toast.success('Scheduled Calls deleted successfully');
    } catch (error) {
      console.error('Error ::', error);
      // toast.error('Error while deleting scheduled calls');
    }
  };

  const onSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const isSelectAll = selectedItems.length === scheduledCalls?.length;
    isSelectAll ? setSelectedItems([]) : setSelectedItems([...scheduledCalls]);
  };

  const onSelectRow = (e: React.MouseEvent<HTMLButtonElement>, log: CallLog) => {
    e.preventDefault();
    e.stopPropagation();

    const index = selectedItems?.findIndex((l) => l.borrowercalls_id === log.borrowercalls_id);
    if (index === -1) {
      addItem(log);
    } else {
      removeItem(log);
    }
  };

  const updatePage = (newPageNumber: number) => {
    setPage(newPageNumber);
    setPageValue(newPageNumber);
    setDataInStorage('page', newPageNumber);
    onScrollToTop();
  };

  const handlePageInputBlur = () => {
    try {
      const val = parseInt(pageValue, 10);
      if (isNaN(val) || !(pageValue > 0 && pageValue <= maxPage)) {
        toast.error(`Please enter a valid page number 1 and ${maxPage}`);
        updatePage(1);
      } else {
        updatePage(val);
      }
    } catch (error) {
      toast.error(`Please enter a valid page number 1 and ${maxPage}`);
      updatePage(1);
    }
  };

  useEffect(() => {
    if (data) {
      setMaxPage(data.pages);
    }
  }, [data]);

  if (isLoading) {
    return <DashboardLoader />;
  }

  return (
    <div className="w-full flex flex-col justify-start items-center gap-4">
      {selectedItems.length > 0 && (
        <div className="flex justify-between items-center gap-x-2 w-full mt-10">
          <div className="font-medium text-blue-950">{`Items selected: ${selectedItems.length}`}</div>
          <PrimaryButton propClass="w-10" onClick={onDelete}>
            Delete
          </PrimaryButton>
        </div>
      )}
      <div className="w-full flex justify-start items-center">
        <div className="text-left text-sm font-medium mt-5">{`Results (${data?.total})`}</div>
      </div>
      <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
        <thead className="border p-2 bg-gray-100 w-full">
          <tr className="border p-2 w-full">
            {enableRowSelection && (
              <td className="border px-3 text-sm font-bold text-blue-950">
                <Checkbox
                  checked={selectedItems.length !== 0 && selectedItems.length === scheduledCalls?.length}
                  onClick={(e) => onSelectAll(e)}
                />
              </td>
            )}
            <td className="border p-2 text-sm font-bold text-blue-950">Borrower Name</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call To</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call Scheduled Time </td>
            <td className="border p-2 text-sm font-bold text-blue-950">Knowl Requested Time</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Previous Call ID</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Borrower Details</td>
          </tr>
        </thead>
        <tbody>
          {scheduledCalls?.length === 0 ? (
            <tr className="w-full h-10 flex justify-center items-center font-medium text-sm">No data found</tr>
          ) : (
            scheduledCalls?.map((log, index) => {
              const isSelected = selectedItems.findIndex((i) => i.borrowercalls_id === log.borrowercalls_id) !== -1;
              const borrowerData = borrowerDetails.find((i) => i.BorrowerCall.id === log.borrowercalls_id);
              return (
                <tr
                  className={twMerge(
                    'text-sm p-2 hover:bg-gray-200 cursor-pointer transition-all ease-in-out',
                    isSelected ? 'bg-blue-100' : '',
                  )}
                  onClick={() => onRowPress(log.borrowercalls_id)}
                >
                  {enableRowSelection && (
                    <td className="border px-3 py-1">
                      <Checkbox checked={isSelected} onClick={(e) => onSelectRow(e, log)} />
                    </td>
                  )}
                  <td className="border px-3 py-1">{log.borrower_name}</td>
                  <td className="border px-3 py-1">{log.call_to || log.borrower_phone_number}</td>
                  <td className="border px-3 py-1">
                    <div className="flex justify-start items-center gap-x-3">
                      <div className="flex justify-start items-center gap-x-2">
                        <div>{moment.utc(log.call_scheduled_time).local().format('lll')}</div>

                        {!editScheduledDate && (
                          <IconButton onClick={(e) => onPressEdit(e, log.borrowercalls_id, index)}>
                            <PencilAltIcon className="w-5 h-5 text-primaryButton" />
                          </IconButton>
                        )}
                      </div>

                      {activeIndex === index && (
                        <div
                          className="flex justify-start items-center gap-x-2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <DatePicker
                            showTimeInput
                            selected={editScheduledDate ? dueDate : log.call_scheduled_time || dueDate}
                            minTime={getXMinutesFromNow(5)}
                            onChange={(date: Date) => setDueDate(date)}
                            className="h-[35px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                            excludeTimes={[getXMinutesFromNow(5)]}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeInputLabel="Time:"
                          />
                          <div className="flex justify-end items-center gap-x-2">
                            <IconButton onClick={(e) => updateScheduledDate(e, log.borrowercalls_id)} title="Update">
                              <CheckIcon className="w-5 h-5 text-primaryButton" />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleScheduledDate(false);
                                setActiveIndex(-1);
                                setBorrowerId(null);
                              }}
                              title="Cancel"
                            >
                              <XIcon className="w-5 h-5 text-red-500" />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="border px-3 py-1">{moment.utc(log.knowl_requested_time).local().format('lll')}</td>
                  <td className="border px-3 py-1">{log.previous_call_sid}</td>
                  <td className="border px-3 py-1">
                    {borrowerData ? (
                      <div className="text-[10px] flex flex-col gap-y-1">
                        <div>Amount: {borrowerData.Borrower.amount_due}</div>
                        <div>Total: {borrowerData.Borrower.total_amount}</div>
                        <div>Due date: {borrowerData.Borrower.due_date}</div>
                      </div>
                    ) : (
                      <div
                        className="underline underline-offset-1 text-blue-700 text-sm font-medium"
                        onClick={() => getBorrowerDetails(log.borrowercalls_id)}
                      >
                        Fetch
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="flex justify-start items-center gap-x-2">
        <SecondaryButton className="w-10" onClick={() => updatePage(page - 1)} disabled={page === 1}>
          Prev
        </SecondaryButton>
        <Tooltip title="Enter page number you want to see ">
          <div className="min-w-20">
            <TextField
              type="number"
              value={pageValue}
              onChange={(v) => setPageValue(v.target.value)}
              onBlur={handlePageInputBlur}
            />
          </div>
        </Tooltip>
        <SecondaryButton className="w-10" onClick={() => updatePage(page + 1)} disabled={page === maxPage}>
          Next
        </SecondaryButton>
      </div>
    </div>
  );
}
