import { AudioBar } from '@components/AudioBar';
import { Transcript } from '@components/Transcript';
import { AnnotationIcon, DownloadIcon, MicrophoneIcon, ServerIcon } from '@heroicons/react/outline';
import { IconButton } from '@mui/material';
import { formatDuration, parseLatencyValues } from '@utils/call.utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CallLogDetails, Telephony } from 'src/typings/call.types';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin: boolean;
};

const API_DOMAIN = import.meta.env.VITE_APP_API_HOST;
const API_BASE = `https://${API_DOMAIN}`;

export default function CallRecording({ callDetails, allowAdmin }: Props) {
  const [audioURL, setAudioURL] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCallRecording = async () => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem('en');
      const response = await fetch(`${API_BASE}/calls/${callDetails?.call_sid}/recording`, {
        headers: {
          Authorization: `${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch audio');
      }

      const arrayBuffer = await response.arrayBuffer(); // Get audio as array buffer
      const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' }); // Convert array buffer to blob
      const url = URL.createObjectURL(blob); // Create object URL from blob
      setAudioURL(url);
    } catch (error) {
      console.error('Error fetching audio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadTranscript = async () => {
    const res = await fetch(callDetails.transcript);
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    const formattedData = data.map((i) => JSON.parse(i)).reverse();
    // Convert the data to JSON string
    const jsonData = JSON.stringify(formattedData, null, 2);

    // Create a Blob with the data and set its MIME type to JSON
    const blob = new Blob([jsonData], { type: 'application/json' });

    // Create a download link dynamically
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${callDetails.call_sid}.json`; // The name of the file to be downloaded

    // Append the link to the document, click it to trigger the download, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (callDetails?.telephony === Telephony.Exotel) {
      fetchCallRecording();
    } else {
      setAudioURL(callDetails.recording);
    }
  }, [callDetails]);

  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="grid grid-flow-col grid-cols-12">
        <div className="flex flex-col gap-4 col-span-8 p-4 mr-4">
          <div className="flex flex-col justify-start items-start gap-4 p-2">
            <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
              <MicrophoneIcon className="w-4 h-4 text-primaryButton" /> Call Recording
            </div>
            {isLoading ? <div>Fetching recording...</div> : <AudioBar audioURL={audioURL} />}
          </div>
        </div>
        <div className="col-span-4">
          <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
            <ServerIcon className="w-4 h-4 text-primaryButton" /> Call Overview
          </div>
          <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Duration</td>
              <td className="border p-2 text-sm font-medium">{formatDuration(callDetails.duration)}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Call Started at</td>
              <td className="border p-2 text-sm font-medium">{moment(callDetails?.call_start).format('lll')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Call Ended at</td>
              <td className="border p-2 text-sm font-medium">{moment(callDetails?.call_end).format('lll')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Latency</td>
              <td className="border p-2 text-sm font-medium">
                {formatDuration(parseLatencyValues(callDetails?.latency))}
              </td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Disconnected by</td>
              <td className="border p-2 text-sm font-medium">{callDetails?.disconnected_by ? 'User' : 'Agent'}</td>
            </tr>
          </table>
        </div>
      </div>
      <hr className="w-full h-[1px] bg-gray-200 mt-4" />
      <div className="flex flex-col justify-start items-start gap-4 p-2">
        <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
          <div className="flex justify-start items-center gap-x-2">
            <AnnotationIcon className="w-4 h-4 text-primaryButton" /> Call Transcript
          </div>

          <IconButton onClick={downloadTranscript} title="Download Transcript">
            <DownloadIcon className="w-4 h-4 text-primaryButton" />
          </IconButton>
        </div>
        <Transcript url={callDetails.transcript} showStats={allowAdmin} />
      </div>
    </div>
  );
}
