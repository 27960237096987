import { TopNavbar } from '@components/TopNavbar';
import { CallStats } from 'src/container/CallStats';

export default function CallStatsPage() {
  return (
    <div className="flex flex-col items-center justify-start w-full h-screen">
      <TopNavbar showBackButton pageTitle="Stats" />
      <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]">
        <CallStats />
      </div>
    </div>
  );
}
