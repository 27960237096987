import { useContext, useState } from 'react';
import BorrowerStats from './statsCards/BorrowerStats';
import { InputLabel } from '@components/TextField';
import { Dropdown } from '@components/Dropdown';
import DatePicker from 'react-datepicker';
import { CompanyNode } from 'src/typings/user.types';
import PickStats from './statsCards/PickStats';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
import TodaysStats from './statsCards/TodaysStats';
import { TimeOfDay } from 'src/typings/stats.types';
import moment from 'moment';
import CombinedPickStats from './statsCards/CombinedPickStats';
import PaidStats from './statsCards/PaidStats';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
import TimeOfDayStats from './statsCards/TimeOfDayStats';
import { telephonyOptions } from 'src/constants/call';

const timeOfDayOptions = Object.entries(TimeOfDay).map((i) => {
  return { id: i[1], name: i[1] };
});

export default function CallStats() {
  const prevCompany = getDataFromStorage('company');
  const [telephony, selectTelephony] = useState<string | null>(null);
  const [timeOfDay, selectTimeofDay] = useState<TimeOfDay | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyNode | null>(prevCompany || null);
  const [dateRange, setDateRange] = useState([moment().subtract(7, 'day').endOf('day').toDate(), new Date()]);
  const [startDate, endDate] = dateRange;
  const { companyList } = useContext(AuthContext) as AuthContextInterface;

  const handleTelephonyChange = (val: any) => {
    if (val) {
      selectTelephony(val.id);
    }
  };

  const handleTimeOfDayChange = (val: any) => {
    if (val) {
      selectTimeofDay(val.id);
    }
  };

  const handleCompanyChange = (val: any) => {
    if (val) {
      setSelectedCompany(val);
      setDataInStorage('company', val);
    }
  };

  return (
    <div className="flex flex-col justify-start items-start gap-4 w-full h-full">
      <div className="flex justify-start items-center gap-x-4">
        <Dropdown
          mandatory
          placeholder="Select Company"
          options={companyList}
          onSelect={handleCompanyChange}
          label="Company"
          defaultValue={prevCompany?.name}
        />
        <div className="flex flex-col">
          <InputLabel label="Date Range" mandatory />
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => setDateRange(update)}
            isClearable={true}
            className="min-w-[250px] h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
          />
        </div>

        {/* <div>
          <Dropdown
            placeholder="Select Telephony"
            options={telephonyOptions}
            onSelect={handleTelephonyChange}
            label="Telephony Partner"
          />
        </div>
        <div>
          <Dropdown
            placeholder="Select Time of Day"
            options={timeOfDayOptions}
            onSelect={handleTimeOfDayChange}
            label="Select Time of Day"
          />
        </div> */}
      </div>
      <div className="flex flex-col justify-start items-start gap-8 w-full h-full">
        {selectedCompany && <TodaysStats companyId={selectedCompany?.id} />}
        {startDate && selectedCompany && (
          <CombinedPickStats startDate={startDate} endDate={endDate} companyId={selectedCompany?.id} />
        )}
        {startDate && selectedCompany && (
          <PickStats startDate={startDate} endDate={endDate} companyId={selectedCompany?.id} />
        )}

        {startDate && selectedCompany && (
          <TimeOfDayStats startDate={startDate} endDate={endDate} companyId={selectedCompany?.id} />
        )}
        <div className="w-full flex justify-between items-start gap-8">
          {startDate && selectedCompany && (
            <BorrowerStats startDate={startDate} endDate={endDate} companyId={selectedCompany?.id} />
          )}

          {startDate && selectedCompany && (
            <PaidStats startDate={startDate} endDate={endDate} companyId={selectedCompany?.id} />
          )}
        </div>
      </div>
    </div>
  );
}
